body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d8e7f3;
}

/* for smooth scrolling on the Patient View page */
html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* highlights for medications, patients, logs */
.highlight {
  background-color: white !important;
  color: #0089d0 !important;
}

/* fonts */

/* Roboto - paragraph font*/
@font-face {
  font-family: 'roboto';
  src: url(./assets/fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'roboto-black';
  src: url(./assets/fonts/Roboto/Roboto-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'roboto-black-italic';
  src: url(./assets/fonts/Roboto/Roboto-BlackItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'roboto-bold';
  src: url(./assets/fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'roboto-bold-italic';
  src: url(./assets/fonts/Roboto/Roboto-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'roboto-italic';
  src: url(./assets/fonts/Roboto/Roboto-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'roboto-light';
  src: url(./assets/fonts/Roboto/Roboto-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'roboto-light-italic';
  src: url(./assets/fonts/Roboto/Roboto-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'roboto-medium';
  src: url(./assets/fonts/Roboto/Roboto-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'roboto-medium-italic';
  src: url(./assets/fonts/Roboto/Roboto-MediumItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'roboto-thin';
  src: url(./assets/fonts/Roboto/Roboto-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'roboto-thin-italic';
  src: url(./assets/fonts/Roboto/Roboto-ThinItalic.ttf) format('truetype');
}

/* Poppins -header font*/
@font-face {
  font-family: 'poppins';
  src: url(./assets/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'poppins-black';
  src: url(./assets/fonts/Poppins/Poppins-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-black-italic';
  src: url(./assets/fonts/Poppins/Poppins-BlackItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-bold';
  src: url(./assets/fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-extra-bold';
  src: url(./assets/fonts/Poppins/Poppins-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-bold-italic';
  src: url(./assets/fonts/Poppins/Poppins-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-italic';
  src: url(./assets/fonts/Poppins/Poppins-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-light';
  src: url(./assets/fonts/Poppins/Poppins-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-light-italic';
  src: url(./assets/fonts/Poppins/Poppins-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-medium';
  src: url(./assets/fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-medium-italic';
  src: url(./assets/fonts/Poppins/Poppins-MediumItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-semi-bold';
  src: url(./assets/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-semi-bold-talic';
  src: url(./assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-thin';
  src: url(./assets/fonts/Poppins/Poppins-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'poppins-thin-italic';
  src: url(./assets/fonts/Poppins/Poppins-ThinItalic.ttf) format('truetype');
}
